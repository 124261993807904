import React, { useState, useEffect } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { thanks9PageLoadedEvent, thanks9PageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios';

function Thanks9({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const translate = (key) =>
    {
        return t(`Thanks9.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 10)
        {
            thanks9PageLoadedEvent();
        }
    }, [activeKey]);

    const proceedToCheckout = async () =>
    {
        await updateToXano(formData.XanoId, '10');

        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);

        thanks9PageCompletedEvent();
    };

    const listStyles = { border: "unset" };

    const updateToXano = async (id, stage) =>
    {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
        const payload = {
            id,
            Stage: stage,
        };

        try
        {
            await axios.patch(url, payload);
            setLoading(false);
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    return (
        <div className="initial-step-container">
            <div className="initial-step-container">
                <Row className="py-4">
                    {/* Styled Container */}
                    <Col xs={12} className="p-4" style={{ backgroundColor: 'lightyellow', border: '3px dashed red', borderRadius: '8px' }}>

                        {/* Header Section */}
                        <h1 className='mt-4' style={{ color: '#FF0000', textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold' }}>
                            {translate('9p90')} {formData.FirstName} {translate('9p91')} {formData.IrsOwe} {translate('9p92')}
                        </h1>

                        <p className='mt-4 text-center' style={{ fontSize: '1.2rem' }}>{translate('9p93')}</p>

                        {/* Highlighted Subtitle */}
                        <h3 className='mt-4' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
                            {translate("9p94")}
                        </h3>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate("9p95")}
                            <span style={{ fontWeight: 'bold' }}>{translate("9p96")} </span>
                            {translate("9p97")}
                            <span style={{ fontWeight: 'bold' }}>{translate("9p98")} </span>
                            {translate("9p99")}
                            <span style={{ fontWeight: 'bold' }}>{translate("9p100")} </span>
                            {translate("9p101")}
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                            {translate("9p102")}
                        </h3>

                        {/* List Group 1 */}
                        <ListGroup as="ul" className="my-3">
                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p103')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p104')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p105')} </span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p106')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p107')} </span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p108')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p109')}</span>
                            </ListGroup.Item>
                        </ListGroup>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('9p110')}
                            <span style={{ fontWeight: 'bold' }}>{translate('9p111')}</span>
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                            {translate("9p112")}
                        </h3>

                        {/* List Group 2 */}
                        <ListGroup as="ul" className="my-3">
                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                {translate('9p121')}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p122')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p123')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                {translate('9p124')}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p125')} </span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p126')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p107')} </span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                                {translate('9p127')}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p128')}</span>
                            </ListGroup.Item>
                        </ListGroup>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {formData.FirstName}, {translate('9p129')}
                        </p>
                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('9p130')}
                            {formData.FirstName}
                            {translate('9p131')}
                        </p>
                    </Col>
                </Row>
            </div>


            <Row className="mt-4">
                <Col>
                    {/* Text Content with Translations */}
                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        <span style={{ fontWeight: 'bold' }}>{translate("9p138")}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("9p139")} {formData.FirstName}, {translate("9p139-1")} {formData.IrsOwe}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("9p140")}</span>
                    </p>

                    {/* Highlighted Heading */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                        {translate('9p90')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>{translate('9p113')} {formData.IrsOwe} {translate('9p114')}</p>

                    {/* Images Section */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/BeatTheIRSVideosml.png" alt="Beat The IRS DIY Video Course" />
                        <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                            {formData.FirstName}, {translate('9p24')}
                        </h1>
                    </div>

                    {/* Important Highlighted Text */}
                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p3")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p4")}</span>
                        {translate("9p5")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p6")}</span>
                        {translate("9p7")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p8")}</span>
                        {translate("9p9")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p10")}</span>
                    </p>

                    {/* Section Heading */}
                    <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
                        {translate("9p11")}
                    </h3>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p12")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p13")}</span>
                        {translate("9p14")}
                    </p>

                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        {translate("9p15")}
                    </p>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3">
                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p16')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p17')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p18')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p19')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p20')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p21')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p22')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p23')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    {/* Images Section */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof1.png" alt="Proof Image" />
                        <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                            {formData.FirstName}, {translate('9p24')}
                        </h1>
                    </div>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>{translate("9p25")}</p>

                    {/* Bold Statements */}
                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{translate("9p26")}</p>
                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{translate("9p27")}</p>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p28")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p29")}</span>
                        {translate("9p30")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p31")}</span>
                        {translate("9p32")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p33")}</span>
                    </p>

                    {/* Proof Images */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof6.png" alt="Proof Image 6" />
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof7.png" alt="Proof Image 7" />
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof8.png" alt="Proof Image 8" />
                    </div>

                    {/* Red Heading */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                        {translate('9p34')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p35")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p36")}</span>
                        {translate("9p37")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p38")}</span>
                        {translate("9p39")}
                    </p>

                    {/* List Group 2 */}
                    <ListGroup as="ul" className="mt-3">
                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p40')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p41')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p42')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p43')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p44')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p45')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p46')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p47')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    {/* More Content Sections */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                        {translate('9p48')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p49")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p50")}</span>
                        {translate("9p51")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p52")}</span>
                        {translate("9p53")}
                    </p>

                    {/* List Group 3 */}
                    <ListGroup as="ul" className="mt-3">
                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p54')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p55')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p56')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p57')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p58')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p59')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    {/* Final Image */}
                    <img style={{ width: "80%", maxWidth: "600px", margin: "20px auto", display: "block" }} src="/productimage-2.png" alt="Product Image" />

                    {/* CTA Button */}
                    <div className='d-flex justify-content-center align-items-center my-4'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: '80%',
                                maxWidth: '480px',
                                backgroundColor: '#FF9900',
                                color: '#000',
                                fontWeight: 'bold',
                                border: '1px solid #FF9900',
                                borderRadius: '8px',
                                padding: '12px 20px',
                                cursor: 'pointer',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                        >
                            {translate('9p132')}
                        </Button>
                    </div>

                    {/* Final Text & CTA Button */}
                    <h2 className='mt-4' style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {formData.FirstName} {translate('9p84')}
                    </h2>

                    <ListGroup as="ul" className="mt-3">
                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p85')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span>{translate('9p86')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p87')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span>{translate('9p88')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p89')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    {/* Secondary CTA Button */}
                    <div className='d-flex justify-content-center align-items-center my-4'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: '80%',
                                maxWidth: '480px',
                                backgroundColor: '#FF9900',
                                color: '#000',
                                fontWeight: 'bold',
                                border: '1px solid #FF9900',
                                borderRadius: '8px',
                                padding: '12px 20px',
                                cursor: 'pointer',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                        >
                            {translate('9p132')}
                        </Button>
                    </div>

                    {/* Money-back Guarantee Section */}
                    <img style={{ width: "80%", maxWidth: "600px", margin: "20px auto", display: "block" }} src="/money-back-guarantee-png-1.png" alt="Money Back Guarantee" />

                    <h2 className='mt-4' style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {formData.FirstName} {translate('9p443')}
                    </h2>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p444")}
                    </p>

                    <p className='mt-4'>
                        <a href="https://automatedtaxcredits.com/money-back-guarantee/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            {translate("9p445")}
                        </a>
                    </p>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p446")}
                    </p>
                </Col>
            </Row>

            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    className="normal-button mt-3"
                    type="button"
                    onClick={proceedToCheckout}
                    style={{
                        width: '80%', // Make the button 60% of the page width
                        maxWidth: '480px', // Optionally set a max width for larger screens
                        backgroundColor: '#FF9900', // Amazon yellow/gold color
                        color: '#000', // Black text
                        fontWeight: 'bold', // Bold text
                        border: '1px solid #FF9900', // Border matching background color
                        borderRadius: '4px', // Rounded corners
                        padding: '10px 20px', // Padding inside the button
                        cursor: 'pointer', // Pointer cursor on hover
                        textAlign: 'center', // Center text
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Slight shadow for depth
                        transition: 'background-color 0.3s ease' // Smooth hover effect
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'} // Darken color on hover
                    onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'} // Restore color when not hovering
                >
                    {translate('9p132')}
                </Button>
            </div>

            <Row className="mt-4">
                <Col>
                    {/* Section Heading */}
                    <h1 className='mt-4' style={{ color: '#000', fontSize: '2.5rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p501')}
                    </h1>

                    {/* Subheading and Paragraph Styling */}
                    <h2 className='mt-4' style={{ color: '#000', fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p502')}
                    </h2>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p503')}
                    </h3>

                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p504")}
                    </p>

                    {/* Section Content */}
                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p505')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p506")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p507')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p508")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p509')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p510")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p511')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p512")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p513')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p514")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p515')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p516")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p517')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p518")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p519')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p520")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p521')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p522")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p523')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p524")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p525')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p526")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p527')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p528")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p529')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p530")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p531')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p532")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p533')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p534")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p535')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p536")}
                    </p>

                    {/* Final Section & Subheadings */}
                    <h2 className='mt-4' style={{ color: '#000', fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p537')}
                    </h2>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p538')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p539")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p540')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p541")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p542')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p543")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p544')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p545")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p546')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p547")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p548')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p549")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p550')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p551")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p552')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p553")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p554')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p555")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p556')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p557")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p558')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p559")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p560')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p561")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p562')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p563")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p564')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p565")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p566')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p567")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p568')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p569")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p570')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p571")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p572')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p573")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: '1.8rem', fontWeight: 'bold' }}>
                        {translate('9p574')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p575")}
                    </p>
                </Col>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default Thanks9;
