import React, { useEffect } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { paymentSuccessPageLoadedEvent } from '../component/FacebookPixelEvents';



function PaymentSuccess({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`PaymentSuccess.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 15)
        {
            paymentSuccessPageLoadedEvent();
        }
    }, [activeKey]);

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h1 className='mt-4' style={{ color: '#FF0000', textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold' }}>
                        {formData.FirstName} {translate('ps1')}
                    </h1>

                    <p className='mt-4 text-center' style={{ fontSize: '1.2rem' }}>{translate('ps2')}</p>

                    <p className='mt-4 text-center' style={{ fontSize: '1.2rem' }}>{translate('ps3')}</p>

                    {/* List Group 1 */}
                    <ListGroup as="ul" className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <a href="#ps4-anchor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('ps4')}</span>
                            </a>
                        </ListGroup.Item>

                        <div style={{ marginLeft: '40px' }}>
                            <ListGroup as="ul" numbered className="my-3">
                                <ListGroup.Item id="ps-7" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps7")}
                                    </a>
                                </ListGroup.Item>

                                <ListGroup.Item id="ps-8" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps8")}
                                    </a>
                                </ListGroup.Item>

                                <ListGroup.Item id="ps-9" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps9")}
                                    </a>
                                </ListGroup.Item>

                                <ListGroup.Item id="ps-10" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps10")}
                                    </a>
                                </ListGroup.Item>

                                <ListGroup.Item id="ps-11" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps11")}
                                    </a>
                                </ListGroup.Item>

                                <ListGroup.Item id="ps-12" as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <a href="#ps" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {translate("ps12")}
                                    </a>
                                </ListGroup.Item>

                            </ListGroup>
                        </div>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem' }}>
                            <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('ps5')}</span>
                            </a>
                        </ListGroup.Item>
                    </ListGroup>

                    <h3 id="ps4-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps4")}
                    </h3>


                    <p className='mt-4 text-center' style={{ fontSize: '1.2rem' }}>{translate('ps6')}</p>

                    <h3 id="ps-7-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps7")}
                    </h3>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016113934"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>

                    <Row className="mt-4">
                        <Col>
                            <p className='mt-4' style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.3rem' }}>
                                {translate("ps13")}
                            </p>

                            <h4 className='mt-4' style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.3rem' }}>
                                {translate("ps14")}
                            </h4>

                            <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                                {translate("ps15")}
                            </p>
                            <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                                {translate("ps16")}
                            </p>

                            {/* Bullet point list with numbered items */}
                            <ListGroup as="ol" numbered className="my-3">
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps17")}</span>: {translate("ps18")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps19")}</span>: {translate("ps20")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps21")}</span>: {translate("ps22")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps23")}</span>: {translate("ps24")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps25")}</span>: {translate("ps26")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps27")}</span>: {translate("ps28")}
                                </ListGroup.Item>
                            </ListGroup>

                            <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                                * {translate("ps29")}
                            </p>

                            <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                                * {translate("ps30")}
                            </p>
                        </Col>
                    </Row>

                    <br />
                    <br />

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps8")}
                    </h4>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016122392"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>


                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps31")}
                    </p>
                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps32")}
                    </p>

                    {/* Bullet point list with numbered items */}
                    <ListGroup as="ol" numbered className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps33")}</span>: {translate("ps34")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps35")}</span>: {translate("ps36")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps37")}</span>: {translate("ps38")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps39")}</span>: {translate("ps40")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps41")}</span>: {translate("ps42")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps43")}</span>: {translate("ps44")}
                        </ListGroup.Item>

                        <ListGroup as="ul" className="my-3" style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps45")}<span style={{ fontWeight: 'bold' }}>{translate("ps46")}</span>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps47")}<span style={{ fontWeight: 'bold' }}>{translate("ps48")}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps49")}<span style={{ fontWeight: 'bold' }}>{translate("ps50")}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps51")}<span style={{ fontWeight: 'bold' }}>{translate("ps52")}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps53")}<span style={{ fontWeight: 'bold' }}>{translate("ps54")}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0' }}>
                                * {translate("ps55")}<span style={{ fontWeight: 'bold' }}>{translate("ps56")}</span>
                            </ListGroup.Item>
                        </ListGroup>

                    </ListGroup>



                    <br />
                    <br />

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps9")}
                    </h4>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016120186"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>

                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps57")}<span style={{ fontWeight: 'bold' }}>{translate("ps58")}</span>{translate("ps59")}
                    </p>

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps60")}
                    </h4>

                    <ListGroup as="ol" numbered className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps61")}</span>: {translate("ps62")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps63")}</span>: {translate("ps64")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps65")}</span>: {translate("ps66")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps67")}</span>: {translate("ps68")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps69")}</span>: {translate("ps70")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps71")}</span>: {translate("ps72")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps73")}</span>: {translate("ps74")}
                        </ListGroup.Item>
                    </ListGroup>

                    <br />
                    <br />

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps10")}
                    </h4>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016118205"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>
                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps75")}
                    </p>

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps76")}
                    </h4>

                    <ListGroup as="ol" numbered className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps77")}</span>: {translate("ps78")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps79")}</span>: {translate("ps80")}
                        </ListGroup.Item>
                        <div style={{ marginLeft: '40px' }}>
                            <ListGroup as="ol" numbered className="my-3">
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps81")}</span>: {translate("ps82")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps83")}</span>: {translate("ps84")}
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps85")}</span>: {translate("ps86")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps97")}</span>: {translate("ps98")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps99")}</span>: {translate("ps100")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps101")}</span>: {translate("ps102")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps103")}</span>: {translate("ps194")}
                        </ListGroup.Item>

                    </ListGroup>



                    <br />
                    <br />

                    <h3 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps11")}
                    </h3>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016116261"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>

                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps105")}
                    </p>

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps106")}
                    </h4>
                    <ListGroup as="ol" numbered className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps107")}</span>: {translate("ps108")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps109")}</span>: {translate("ps110")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps110-1")}</span>: {translate("ps111")}
                        </ListGroup.Item>

                        <div style={{ marginLeft: '40px' }}>
                            <ListGroup as="ul" numbered className="my-3">
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps112")}</span>: {translate("ps113")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps114")}</span>: {translate("ps115")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps116")}</span>: {translate("ps117")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps118")}</span>: {translate("ps119")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps120")}</span>: {translate("ps121")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps122")}</span>: {translate("ps123")}
                                </ListGroup.Item>
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps124")}</span>: {translate("ps125")}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps126")}</span>: {translate("ps127")}
                                </ListGroup.Item>
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps128")}</span>: {translate("ps129")}
                                </ListGroup.Item>
                                <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                                    <span style={{ fontWeight: 'bold' }}>{translate("ps130")}</span>: {translate("ps131")}
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </ListGroup>

                    <br />
                    <br />
                    <h3 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps12")}
                    </h3>

                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                        <iframe
                            src="https://player.vimeo.com/video/1016114912"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        />
                    </div>
                    <p className='mt-4' style={{ textAlign: 'left', fontSize: '1.1rem', }}>
                        {translate("ps132")}
                    </p>

                    <h4 id="ps5-anchor" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps133")}
                    </h4>

                    <ListGroup as="ol" numbered className="my-3">
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps134")}</span>: {translate("ps135")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps136")}</span>: {translate("ps137")}
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps138")}</span>: {translate("ps139")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps140")}</span>: {translate("ps141")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps142")}</span>: {translate("ps143")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps144")}</span>: {translate("ps145")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps146")}</span>: {translate("ps147")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps148")}</span>: {translate("ps149")}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ border: 'none', padding: '8px 0', fontSize: '1.1rem', textAlign: 'left' }}>
                            <span style={{ fontWeight: 'bold' }}>{translate("ps150")}</span>: {translate("ps151")}
                        </ListGroup.Item>

                    </ListGroup>

                    <h2 id="IRsTaxAssistant" className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                        {translate("ps5")}
                    </h2>


                    {/* <p className='mt-3 text-start'>{translate('PleaseCheckYourEmailForNextSteps')}</p>
                    <p className='mt-3 text-start'>{translate('IfYouHaveAnyQuestionsOrConcerns')}</p>
                    <p className='mt-3 text-start'>{translate('YouCanAlsoUseLiveChat')}</p> */}
                </Col>
            </Row>
        </div>
    );
}

export default PaymentSuccess;
