import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../component/helper';
import ZipcodeAutoComplete from '../component/ZipcodeAutoComplete';
import { zipcodePageLoadedEvent, zipcodePageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios'

function Zipcode({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const commomTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`Zipcode.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 4)
        {
            zipcodePageLoadedEvent();
        }

    }, [activeKey]);

    const validate = () =>
    {
        const errors = {};

        if (isEmpty(formData.Zipcode)) errors.Zipcode = commomTranslate('FieldRequired');

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return false;
        }

        return true;
    };

    const next = async () =>
    {
        setError({});
        if (!validate())
        {
            return;
        }

        await updateToXano(formData.XanoId, '4');

        zipcodePageCompletedEvent();

        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);
    };

    const updateToXano = async (id, stage) =>
        {
            setLoading(true);
    
            const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
            const payload = { 
                id,
                Stage: stage,
             };
    
            try
            {
                await axios.patch(url, payload);                      
                setLoading(false);
            }
            catch (e)
            {
                setLoading(false);
                console.log('Error: ', e);
            }
        };

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('WhatsYourZipcode')}</h2>
                    <h4>{translate('IrsHasDifferentReliefRequirements')}</h4>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <ZipcodeAutoComplete formData={formData} setFormData={setFormData} />
                    <p className="validation-error">{error.Zipcode}</p>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next();
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Continue')}
                    </Button>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default Zipcode;
