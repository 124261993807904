import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { contactReasonPageLoadedEvent, contactReasonPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios'

function ContactReason({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const commomTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`ContactReason.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 8)
        {
            contactReasonPageLoadedEvent();
        }
    }, [activeKey]);

    const selected = (e) =>
    {
        if (!formData.ContactReason)
        {
            formData.ContactReason = [e];
        }
        else
        {
            const newReasons = formData.ContactReason;
            const idx = newReasons.indexOf(e);
            if (idx === -1)
            {
                newReasons.push(e);
            }
            else
            {
                newReasons.splice(idx, 1);
            }

            formData.ContactReason = newReasons;
        }

        setFormData({ ...formData });
    };

    const isSelected = (e) =>
    {
        if (!formData.ContactReason) return false;

        return formData.ContactReason.includes(e);
    };

    const validate = () =>
    {
        const errors = {};
        if (!formData.ContactReason || formData.ContactReason.length === 0) errors.ContactReason = commomTranslate('SelectOne');

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return false;
        }

        return true;
    };

    const next = async () =>
    {
        if (!validate())
        {
            return;
        }

        await updateToXano(formData.XanoId, '8');

        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);

        contactReasonPageCompletedEvent();
    };

    const updateToXano = async (id, stage) =>
        {
            setLoading(true);
    
            const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
            const payload = { 
                id,
                Stage: stage,
             };
    
            try
            {
                await axios.patch(url, payload);                      
                setLoading(false);
            }
            catch (e)
            {
                setLoading(false);
                console.log('Error: ', e);
            }
        };

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('WhatPromptedYouToReachOutToUs')}</h2>
                    <p>{translate('SelectAllThatApply')}</p>
                </Col>
            </Row>


            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Received An Irs Notice');

                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Received An Irs Notice') && { color: '#F6921E' }), }}
                    >
                        {translate('ReceivedAnIrsNotice')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Wages Garnished Or Bank Account Levied');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Wages Garnished Or Bank Account Levied') && { color: '#F6921E' }), }}
                    >
                        {translate('WagesGarnishedOrBankAccountLevied')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Lien Placed On Property');

                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Lien Placed On Property') && { color: '#F6921E' }), }}
                    >
                        {translate('LienPlacedOnProperty')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Marital Status Changed');

                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Marital Status Changed') && { color: '#F6921E' }), }}
                    >
                        {translate('MaritalStatusChanged')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Other');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Other') && { color: '#F6921E' }), }}
                    >
                        {translate('Other')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <p className="validation-error">{error.ContactReason}</p>

                    <Button
                        className="btn-brand"
                        type="button"
                        onClick={() =>
                        {
                            next();
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Continue')}
                    </Button>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default ContactReason;
