import React, { useEffect, useState } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { issueReasonPageLoadedEvent, issueReasonPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios';

function IssueReason({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const commomTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`IssueReason.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 3)
        {
            issueReasonPageLoadedEvent();
        }

    }, [activeKey]);

    const selected = (e) =>
    {
        if (!formData.IssueReason)
        {
            formData.IssueReason = [e];
        }
        else
        {
            const newReasons = formData.IssueReason;
            const idx = newReasons.indexOf(e);
            if (idx === -1)
            {
                newReasons.push(e);
            }
            else
            {
                newReasons.splice(idx, 1);
            }

            formData.IssueReason = newReasons;
        }

        setFormData({ ...formData });
    };

    const isSelected = (e) =>
    {
        if (!formData.IssueReason) return false;

        return formData.IssueReason.includes(e);
    };

    const validate = () =>
    {
        const errors = {};
        if (!formData.IssueReason || formData.IssueReason.length === 0) errors.IssueReason = commomTranslate('SelectOne');

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return false;
        }

        return true;
    };

    const next = async () =>
    {
        if (!validate())
        {
            return;
        }
        
        await updateToXano(formData.XanoId, '3');

        issueReasonPageCompletedEvent();

        // Skip the next Zipcode Step
        const newActiveKey = +activeKey + 2;
        setActiveKey(newActiveKey);
    };

    const updateToXano = async (id, stage) =>
        {
            setLoading(true);
    
            const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
            const payload = { 
                id,
                Stage: stage,
             };
    
            try
            {
                await axios.patch(url, payload);                      
                setLoading(false);
            }
            catch (e)
            {
                setLoading(false);
                console.log('Error: ', e);
            }
        };

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('WhatCausedOrContributedYourTaxIssue')}</h2>
                    <p>{translate('SelectAllThatApply')}</p>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Personal Or Business Financial Hardship');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Personal Or Business Financial Hardship') && { color: '#F6921E' }), }}
                    >
                        {translate('PersonalOrBusinessFinancialHardship')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Life disruption');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Life disruption') && { color: '#F6921E' }), }}
                    >
                        {translate('LifeDisruption')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Not Enough Withheld');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Not Enough Withheld') && { color: '#F6921E' }), }}
                    >
                        {translate('NotEnoughWithheld')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Overwhelmed Too Busy To File');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Overwhelmed Too Busy To File') && { color: '#F6921E' }), }}
                    >
                        {translate('TooBusyToFiIe')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            selected('Other');
                        }}
                        style={{ width: 240, borderRadius: 0, ...(isSelected('Other') && { color: '#F6921E' }), }}
                    >
                        {translate('Other')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <p className="validation-error">{error.IssueReason}</p>

                    <Button
                        className="btn-brand"
                        type="button"
                        onClick={() =>
                        {
                            next();
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Continue')}
                    </Button>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default IssueReason;
