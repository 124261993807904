import React, { useEffect, useState } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { taxTypePageLoadedEvent, taxTypePageCompletedEvent } from '../component/FacebookPixelEvents';
import axios from 'axios';
import FullScreenSpinner from '../component/FullScreenSpinner';

function TaxType({ activeKey, setActiveKey, formData, setFormData })
{

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`TaxType.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 1)
        {
            taxTypePageLoadedEvent();
        }

    }, [activeKey]);

    const next = async (e) =>
    {
        // Skip the next PaymentPlan Step
        const xanoId = await postToXano();
        
        const newActiveKey = +activeKey + 2;
        setActiveKey(newActiveKey);

        setFormData({
            ...formData,
            TaxType: e,
            PaymentPlan: 'NA',
            Zipcode: '-',
            State: '-',
            County: '-',
            XanoId: xanoId
        });

        taxTypePageCompletedEvent();
    };

        const postToXano = async () =>
    {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:mf0ILYj9/taxquotesuserdata';
        const payload = { 
            Stage: '1',
         };

        try
        {
            const response = await axios.post(url, payload);
            const xanoId = response.data.id;
                  
            setLoading(false);
            return xanoId;
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };




    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('IsYourTaxPersonalRrBusinessRelated')}</h2>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('Personal');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Personal')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('Business');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Business')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('Both');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Both')}
                    </Button>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default TaxType;
