export const taxTypePageLoadedEvent = () => window.fbq('track', 'TaxTypePageLoaded');
export const taxTypePageCompletedEvent = () => window.fbq('track', 'TaxTypePageCompleted');

export const paymentPlanPageLoadedEvent = () => window.fbq('track', 'PaymentPlanPageLoaded');
export const paymentPlanPageCompletedEvent = () => window.fbq('track', 'PaymentPlanPageCompleted');

export const issueReasonPageLoadedEvent = () => window.fbq('track', 'IssueReasonPageLoaded');
export const issueReasonPageCompletedEvent = () => window.fbq('track', 'IssueReasonPageCompleted');

export const zipcodePageLoadedEvent = () => window.fbq('track', 'ZipcodePageLoaded');
export const zipcodePageCompletedEvent = () => window.fbq('track', 'ZipcodePageCompleted');

export const unfiledReturnPageLoadedEvent = () => window.fbq('track', 'UnfiledReturnPageLoaded');
export const unfiledReturnPageCompletedEvent = () => window.fbq('track', 'UnfiledReturnPageCompleted');

export const irsOwePageLoadedEvent = () => window.fbq('track', 'IrsOwePageLoaded');
export const irsOwePageCompletedEvent = () => window.fbq('track', 'IrsOwePageCompleted');

export const protectAssetsPageLoadedEvent = () => window.fbq('track', 'ProtectAssetsPageLoaded');
export const protectAssetsPageCompletedEvent = () => window.fbq('track', 'ProtectAssetsPageCompleted');

export const contactReasonPageLoadedEvent = () => window.fbq('track', 'ContactReasonPageLoaded');
export const contactReasonPageCompletedEvent = () => window.fbq('track', 'ContactReasonPageCompleted');

export const contactInfoPageLoadedEvent = () => window.fbq('track', 'ContactInfoPageLoaded');
export const contactInfoPageCompletedEvent = () => window.fbq('track', 'ContactInfoPageCompleted');

export const thanks9PageLoadedEvent = () => window.fbq('track', 'Thanks9PageLoaded');
export const thanks9PageCompletedEvent = () => window.fbq('track', 'Thank9PageCompleted');

export const checkout9PageLoadedEvent = () => window.fbq('track', 'Checkout9PageLoaded');
export const checkout9PageCompletedEvent = () => window.fbq('track', 'Checkout9PageCompleted');

export const upsell299PageLoadedEvent = () => window.fbq('track', 'Upsell299PageLoaded');
export const upsell299PageCompletedEvent = () => window.fbq('track', 'Upsell299PageCompleted');

export const thanks299PageLoadedEvent = () => window.fbq('track', 'Thanks299PageLoaded');
export const thanks299PageCompletedEvent = () => window.fbq('track', 'Thank299PageCompleted');

export const checkout299PageLoadedEvent = () => window.fbq('track', 'Checkout299PageLoaded');
export const checkout299PageCompletedEvent = () => window.fbq('track', 'Checkout299PageCompleted');

export const paymentSuccessPageLoadedEvent = () => window.fbq('track', 'PaymentSuccessPageLoaded');
// export const paymentSuccessPageCompletedEvent = () => window.fbq('track', 'PaymentSuccessPageCompleted');
