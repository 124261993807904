import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PhoneInput from "react-phone-input-2";
import Misspeller from "../component/Misspeller";
import { isEmpty, textRegex, emailRegex } from '../component/helper';
import FullScreenSpinner from '../component/FullScreenSpinner';
import ZipcodeAutoComplete from '../component/ZipcodeAutoComplete';
import CreditCardInput from 'react-credit-card-input';
import { checkout299PageLoadedEvent, checkout299PageCompletedEvent } from '../component/FacebookPixelEvents';


function Checkout299({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [singleLineError, setSingleLineError] = useState('');
    const [errors, setErrors] = useState({});
    const [couponCodeError, setCouponCodeError] = useState('');
    const [loading, setLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCvv, setCardCvv] = useState('');

    const [discount, setDiscount] = useState(0);
    const [couponApplied, setCouponApplied] = useState(false);

    const price = 299;


    const translate = (key) =>
    {
        return t(`Checkout299.${key}`);
    };

    const commomTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 14)
        {
            if (formData.CardNumber) setCardNumber(formData.CardNumber);
            if (formData.CardExpiry) setCardExpiry(formData.CardExpiry);
            if (formData.CardCvv) setCardCvv(formData.CardCvv);

            checkout299PageLoadedEvent();
        }
    }, [activeKey]);

    const validateCardNumber = () =>
    {
        const regex = /^[0-9]{13,19}$/;
        return regex.test(cardNumber.replace(/\s+/g, ''));
    };

    const validateExpiryDate = () =>
    {
        const [month, year] = cardExpiry.split('/').map((val) => val.trim());
        const currentYear = new Date().getFullYear().toString().slice(-2);
        const currentMonth = new Date().getMonth() + 1;
        return (
            month &&
            year &&
            month > 0 &&
            month <= 12 &&
            (year > currentYear || (year === currentYear && month >= currentMonth))
        );
    };

    const validateCvv = () =>
    {
        const regex = /^[0-9]{3,4}$/;
        return regex.test(cardCvv);
    };

    const validate = () =>
    {
        const errors = {};

        if (isEmpty(formData.FirstName)) errors.FirstName = commomTranslate('FieldRequired');
        else if (!textRegex.test(formData.FirstName)) errors.FirstName = commomTranslate('NameCanOnlyLetters');

        if (isEmpty(formData.LastName)) errors.LastName = commomTranslate('FieldRequired');
        else if (!textRegex.test(formData.LastName)) errors.LastName = commomTranslate('NameCanOnlyLetters');

        if (isEmpty(formData.Email)) errors.Email = commomTranslate('FieldRequired');
        else if (!emailRegex.test(formData.Email)) errors.Email = commomTranslate('EnterValidEmailAddress');

        if (isEmpty(formData.Phone)) errors.Phone = commomTranslate('FieldRequired');

        if (isEmpty(formData.Zipcode)) errors.Zipcode = commomTranslate('FieldRequired');

        if (Object.values(errors).find((e) => e))
        {
            setErrors(errors);
            return false;
        }

        return true;
    };

    const handlePayment = (e) =>
    {
        e.preventDefault();
        setSingleLineError('');
        setErrors({});

        const validated = validate();

        console.log('errors: ', errors);

        if (!validated)
        {
            setLoading(false);
        }

        if (!validateCardNumber())
        {
            setSingleLineError('Card number is invalid');
            return;
        }

        if (!validateExpiryDate())
        {
            setSingleLineError('Card expiry is invalid');
            return;
        }

        if (!validateCvv())
        {
            setSingleLineError('Card CVV is invalid');
            return;
        }

        if (!validated)
        {
            return;
        }

        setLoading(true);
        setSingleLineError('');

        const authData = {
            clientKey: '92hAYTqf2YE3sce82q7GRfnyAh2RE9KE3q64zv98GkAhRftXfC3jREq7w7eaWsJ6',
            apiLoginID: '96n54BNfH87',
        };

        const cardData = {
            cardNumber: cardNumber.replace(/\s+/g, ''),
            month: cardExpiry.split('/')[0].trim(),
            year: cardExpiry.split('/')[1].trim(),
            cardCode: cardCvv,
        };

        const customerData = {
            firstName: formData.FirstName,
            lastName: formData.LastName,
            email: formData.Email,
            phone: formData.Phone,
            billingAddress: {
                firstName: formData.FirstName,
                lastName: formData.LastName,
                // address: billingAddress,
                // city: billingCity,
                state: formData.State,
                zip: formData.Zipcode,
                country: formData.County
            }
        };

        const secureData = {
            authData,
            cardData,
            customerData
        };

        window.Accept.dispatchData(secureData, responseHandler);
    };

    const redeemCoupon = (e) =>
    {
        e.preventDefault();

        if (!formData.CouponCode)
        {
            setCouponCodeError('Coupon code is invalid');
            return;
        }

        setLoading(true);
        setCouponCodeError('');

        axios.post('https://tda-server.automatedtaxcredits.com/api/redeem-coupon', { Code: formData.CouponCode })
            .then((res) =>
            {
                const discountedPrice = res.data.price;

                setLoading(false);
                setCouponApplied(true);
                setDiscount(price - discountedPrice);
            })
            .catch((err) =>
            {
                setLoading(false);
                setCouponCodeError(err?.response?.data?.message ?? 'Coupon code is invalid');
            });
    };

    const responseHandler = (response) =>
    {
        if (response.messages.resultCode === "Error")
        {
            setLoading(false);
            console.error(response.messages.message[0].text);
            setSingleLineError(response.messages.message[0].text);
        }
        else
        {
            const { opaqueData } = response;

            const payload = { opaqueData, CouponCode: null, ProductId: 299 };

            if (formData.CouponCode)
            {
                payload.CouponCode = formData.CouponCode;
            }

            axios.post('https://tda-server.automatedtaxcredits.com/api/process-payment', payload)
                .then(async (res) =>
                {
                    setLoading(false);

                    // TODO: Update Zoho and Xano with this transaction
                    // const zohoRecordId = await postToZoho();
                    // await postToXano(res.data.txnId, zohoRecordId);

                    await sendEmail('ben@automateboring.net', 'NewClient Sale');
                    await updateToXano(formData.XanoId, '11');
                    const newActiveKey = +activeKey + 1;
                    setActiveKey(newActiveKey);

                    setFormData({
                        ...formData,
                        Purchsed299: true
                    });

                    checkout299PageCompletedEvent();
                })
                .catch((err) =>
                {
                    setLoading(false);
                    setSingleLineError(err?.response?.data?.message ?? 'Transaction failed');
                    console.error('Payment failed', err);
                });
        }
    };

    const updateToXano = async (id, stage) =>
    {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
        const payload = {
            id,
            Stage: stage,
        };

        try
        {
            await axios.patch(url, payload);
            setLoading(false);
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    // const postToZoho = async () =>
    // {
    //     setLoading(true);

    //     const url = 'https://zoho.automatedtaxcredits.com/api/insert_tax_intake';
    //     const payload = { ...formData };

    //     try
    //     {
    //         const response = await axios.post(url, payload);
    //         setLoading(false);
    //         return response.data.data[0].details.id;
    //     }
    //     catch (e)
    //     {
    //         setLoading(false);
    //         console.log('Error: ', e);
    //     }
    // };

    // const postToXano = async (txnId, zohoId) =>
    // {
    //     setLoading(true);

    //     const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:mf0ILYj9/taxquotesuserdata';
    //     const payload = { ...formData, TransactionId: txnId, ZohoId: zohoId };

    //     try
    //     {
    //         await axios.post(url, payload);
    //         setLoading(false);

    //         const newActiveKey = +activeKey + 1;
    //         setActiveKey(newActiveKey);

    //         checkoutPageCompletedEvent();
    //     }
    //     catch (e)
    //     {
    //         setLoading(false);
    //         console.log('Error: ', e);
    //     }
    // };

    const sendEmail = async (to, subject) =>
    {
        setLoading(true);

        const url = 'https://zoho.automatedtaxcredits.com/api/send_mail';

        const payload = {
            to,
            subject,
            text: ''
        };

        try
        {
            await axios.post(url, payload);

            setLoading(false);
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    return (
        <div className="initial-step-container">
            <Row className='mt-3'>
                <Col>
                    <h2 className="heading_emp text-center">{formData.FirstName} {translate('CompleteYourPurchaseHere')}</h2>
                    <p className='mt-4'>
                        {translate("299p40")}
                    </p>

                    <div className='border my-4 rounded p-4'>
                        <Row>
                            <h5>{translate('OrderSummary')}</h5>
                            <div className='d-flex align-items-end justify-content-between'>
                                <span>Tax Assistant AI x 1</span>
                                <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</span>
                            </div>

                            <div className='border-bottom my-2'></div>
                            <div className='d-flex align-items-end justify-content-between'>
                                <span>{translate('Subtotal')}</span>
                                <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</span>
                            </div>

                            {couponApplied && (
                                <>
                                    <div className='border-bottom my-2'></div>
                                    <div className='d-flex align-items-end justify-content-between'>
                                        <span>{translate('Discount')}</span>
                                        <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(discount)}</span>
                                    </div>
                                </>
                            )}

                            <div className='border-bottom my-2'></div>
                            <div className='d-flex align-items-end justify-content-between' style={{ fontWeight: 'bold' }}>
                                <span>{translate('Total')}</span>
                                <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price - discount)}</span>
                            </div>
                        </Row>
                    </div>

                    <img style={{ width: "200px", marginTop: "12px", marginBottom: "12px" }} src="/safe-checkout.png" />

                    {/* {!formData.Purchsed9 && ( */}
                    <Form.Group className="p-3 border border-primary rounded">
                        <CreditCardInput
                            cardCVCInputProps={{
                                value: formData.CardCvv,
                                onChange: e =>
                                {
                                    setCardCvv(e.target.value);
                                }
                            }}
                            cardExpiryInputProps={{
                                value: formData.CardExpiry,
                                onChange: e =>
                                {
                                    setCardExpiry(e.target.value);
                                }
                            }}
                            cardNumberInputProps={{
                                value: formData.CardNumber,
                                onChange: e =>
                                {
                                    setCardNumber(e.target.value);
                                }
                            }}
                        />
                    </Form.Group>
                    {/* )} */}

                    <Row>
                        <Col>
                            <Form.Group className="mb-3 mt-4">
                                <Form.Label>{translate('CouponCode')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData.CouponCode}
                                    onChange={(e) => setFormData({ ...formData, CouponCode: e.target.value })}
                                />
                                <p className="validation-error">{couponCodeError}</p>
                            </Form.Group>
                        </Col>

                        <Col className='d-flex align-items-end justify-content-end mb-3'>
                            <Button
                                className="next-button"
                                type="button"
                                onClick={(e) =>
                                {
                                    redeemCoupon(e);
                                }}
                                style={{ width: 'fit-content', borderRadius: 0 }}
                            >
                                {translate('Redeem')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* {!formData.Purchsed9 && ( */}
            <>
                <Row className='mt-3'>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('FirstName')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.FirstName}
                                onChange={(e) => setFormData({ ...formData, FirstName: e.target.value })}
                            />
                            <p className="validation-error">{errors.FirstName}</p>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('LastName')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.LastName}
                                onChange={(e) => setFormData({ ...formData, LastName: e.target.value })}
                            />
                            <p className="validation-error">{errors.LastName}</p>
                        </Form.Group>
                    </Col>
                </Row>


                <Row className='mt-3'>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('Email')}</Form.Label>
                            <Misspeller
                                value={formData.Email}
                                onChange={(email) =>
                                {
                                    setFormData({ ...formData, Email: email });
                                }}
                            />
                            <p className="validation-error">{errors.Email}</p>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6}>
                        <strong>{translate('Phone')} </strong>
                        <PhoneInput
                            style={{ marginTop: 10 }}
                            country={"us"}
                            onlyCountries={["us"]}
                            value={formData.Phone}
                            disableDropdown={true}
                            countryCodeEditable={false}
                            onChange={(e) => setFormData({ ...formData, Phone: e })}
                        />
                        <p className="validation-error">{errors.Phone}</p>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <ZipcodeAutoComplete formData={formData} setFormData={setFormData} value={formData.Zipcode} />
                        <p className="validation-error">{errors.Zipcode}</p>
                    </Col>
                </Row>
            </>
            {/* )} */}

            <Row className="text-center mt-3">
                <Col>
                    <div className='my-4'>
                        <p className="validation-error">{singleLineError}</p>
                    </div>

                    <Button
                        className="btn-brand"
                        type="button"
                        onClick={(e) =>
                        {
                            handlePayment(e);
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('PayNow')}
                    </Button>
                </Col>
            </Row>

            <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", paddingTop: "20px" }} src="SafeCheckoutTP-1.png" />

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default Checkout299;
