import React, { useState, useEffect } from "react";
import { Row, Button, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import axios from 'axios';

import "react-phone-input-2/lib/style.css";

import Misspeller from "../component/Misspeller";
import { isEmpty, textRegex, emailRegex } from '../component/helper';
import FullScreenSpinner from '../component/FullScreenSpinner';
import { contactInfoPageLoadedEvent, contactInfoPageCompletedEvent } from '../component/FacebookPixelEvents';


function ContactInfo({ activeKey, setActiveKey, formData, setFormData, directLinkTo299 })
{
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const commomTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`ContactInfo.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 10)
        {
            contactInfoPageLoadedEvent();
        }
    }, [activeKey]);

    const validate = () =>
    {
        const errors = {};

        if (isEmpty(formData.FirstName)) errors.FirstName = commomTranslate('FieldRequired');
        else if (!textRegex.test(formData.FirstName)) errors.FirstName = commomTranslate('NameCanOnlyLetters');

        if (isEmpty(formData.LastName)) errors.LastName = commomTranslate('FieldRequired');
        else if (!textRegex.test(formData.LastName)) errors.LastName = commomTranslate('NameCanOnlyLetters');

        if (isEmpty(formData.Email)) errors.Email = commomTranslate('FieldRequired');
        else if (!emailRegex.test(formData.Email)) errors.Email = commomTranslate('EnterValidEmailAddress');

        if (isEmpty(formData.Phone)) errors.Phone = commomTranslate('FieldRequired');

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return false;
        }

        return true;
    };

    const goToNextStep = async () =>
    {
        setError({});
        if (!validate())
        {
            setLoading(false);
            return;
        }

        await postToZoho();
    };

    const postToZoho = async () =>
    {
        setLoading(true);

        const url = 'https://zoho.automatedtaxcredits.com/api/insert_tax_intake';
        const payload = { ...formData };

        try
        {
            const response = await axios.post(url, payload);
            setLoading(false);
            const zohoId = response.data.data[0].details.id;
            setFormData({
                ...formData,
                ZohoId: zohoId
            });

            await updateToXano();
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    const updateToXano = async () =>
    {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_tax_quotes_data';
        const payload = { ...formData, Stage: '9' };

        try
        {
            await axios.patch(url, payload);

            setLoading(false);
            setFormData({
                ...formData
            });

            await sendEmail('ben@automateboring.net', 'NewLead');

            let newActiveKey = +activeKey + 1;

            if (directLinkTo299)
            {
                // Direct link to 299 page
                newActiveKey = 13;
            }

            setActiveKey(newActiveKey);

            contactInfoPageCompletedEvent();
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    const sendEmail = async (to, subject) =>
    {
        setLoading(true);

        const url = 'https://zoho.automatedtaxcredits.com/api/send_mail';

        const payload = {
            to,
            subject,
            text: ''
        };

        try
        {
            await axios.post(url, payload);

            setLoading(false);
        }
        catch (e)
        {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('JustTheBasics')}</h2>
                    <h4>{translate('NeverSellOrShareYourInformation')}</h4>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{translate('FirstName')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.FirstName}
                            onChange={(e) => setFormData({ ...formData, FirstName: e.target.value })}
                        />
                        <p className="validation-error">{error.FirstName}</p>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{translate('LastName')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.LastName}
                            onChange={(e) => setFormData({ ...formData, LastName: e.target.value })}
                        />
                        <p className="validation-error">{error.LastName}</p>
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{translate('Email')}</Form.Label>
                        <Misspeller
                            value={formData.Email}
                            onChange={(email) =>
                            {
                                setFormData({ ...formData, Email: email });
                            }}
                        />
                        <p className="validation-error">{error.Email}</p>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6}>
                    <strong>{translate('Phone')} </strong>
                    <PhoneInput
                        style={{ marginTop: 10 }}
                        country={"us"}
                        onlyCountries={["us"]}
                        value={formData.Phone}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onChange={(e) => setFormData({ ...formData, Phone: e })}
                    />
                    <p className="validation-error">{error.Phone}</p>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <h4>{translate('JoinPeopleWhoHaveSubmitted')}</h4>

                    <p>{translate('TermsAndConditions')}</p>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            goToNextStep();
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Continue')}
                    </Button>

                    {/* <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = 13; // activity key for Thanks299 page.
                            goToNextStep(newActiveKey);
                        }}
                        style={{ width: 240, borderRadius: 0, marginLeft: '12px' }}
                    >
                        {translate('ContinueWith299')}
                    </Button> */}
                </Col>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default ContactInfo;
