import React, { useEffect } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { thanks299PageLoadedEvent, thanks299PageCompletedEvent } from '../component/FacebookPixelEvents';


function Thanks299({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`Thanks299.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 13)
        {
            thanks299PageLoadedEvent();
        }
    }, [activeKey]);

    const proceedToCheckout = () =>
    {
        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);

        thanks299PageCompletedEvent();
    };

    const listStyles = { border: "unset" };

    return (
        <div className="initial-step-container">
            <Row className="mt-4">
                <Col>
                    <p className='mt-4'>
                        <span style={{ fontWeight: 'bold' }}>{translate("299p1")} </span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("299p2")} {formData.FirstName} {translate("299p2-1")} </span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("299p3")}</span>
                    </p>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p5')}
                    </h2>

                    <p className='mt-4'>
                        {translate("299p6")}{formData.IrsOwe}{translate("299p6-1-1")}
                    </p>

                    <p className='mt-4' style={{ fontWeight: 'bold' }}>
                        {translate("299p6-1")}
                    </p>

                    <p className='mt-4'>
                        {translate("299p7")}
                    </p>

                    <img style={{ width: "80%", marginTop: "12px", marginBottom: "12px", display: "block", marginLeft: "auto", marginRight: "auto" }} src="/3steps-final.png" />

                    <p className='mt-4'>
                        {translate("299p7-1")}
                    </p>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p8')}
                    </h2>

                    <p className='mt-4'>
                        {translate("299p9")}
                    </p>

                    <p className='mt-4'><span style={{ fontWeight: 'bold' }}>
                        {translate("299p10")}</span>
                    </p>

                    <p className='mt-4'>
                        {translate("299p11")}
                    </p>

                    <p className='mt-4'><span style={{ fontWeight: 'bold' }}>
                        {translate("299p12")}</span>
                    </p>

                    <p className='mt-4'>
                        {translate("299p13")}
                    </p>

                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px" }} src="/OldWayNewWay1-1.png" />

                    <h3 className='mt-4' style={{ color: '#FF0000' }}>
                        {translate("299p14")}
                    </h3>

                    <ListGroup as="ul" style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p15')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p16')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p17')} {formData.IrsOwe} {translate('299p17-1')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p18')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p19')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p20')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p21')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p22')}</span>
                        </ListGroup.Item>

                    </ListGroup>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p23')}
                    </h2>

                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof1.png" />
                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof2.png" />

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p24')}
                    </h2>

                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof3.png" />

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p25')}
                    </h2>

                    <p className='mt-4'>
                        {translate("299p26")} {formData.FirstName}, {translate("299p26-1")}
                    </p>

                    <p className='mt-4'>
                        {translate("299p27")} {formData.IrsOwe} {translate("299p27-1")}
                    </p>

                    <p className='mt-4'>
                        {translate("299p28")}
                    </p>

                    <p className='mt-4'>
                        {translate("299p29")}
                    </p>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p30')}
                    </h2>

                    <ListGroup as="ul" style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p31')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p32')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p33')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p34')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('299p35')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('299p36')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p37')}
                    </h2>

                    <p className='mt-4'>
                        {translate("299p38")}{formData.IrsOwe}{translate("299p38-1")}
                    </p>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: 240,
                                borderRadius: 0,
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                border: 'none'  // Optionally remove border if desired
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>
                        {formData.FirstName}, {translate('299p24')}
                    </h2>

                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof5.png" />
                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof6.png" />
                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof7.png" />
                    <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px", padding: "20px 0" }} src="/Proof8.png" />

                    {/* This is for image  */}
                    {/* <img style={{ width: "100%", marginTop: "12px", marginBottom: "12px" }} src="/replace-image-name-here from public folder" /> */}

                    {/* This is for para  */}
                    {/* <p className='mt-4'>{translate('YouWontBelieveHowEasilyYouCanSettleYourIrsDebt')}</p> */}

                    {/* This is for heading  */}
                    <h2 className='mt-4' style={{ color: '#0f4761' }}>
                        {formData.FirstName} {translate('YourPersonalizedIrsBlueprint')}:
                    </h2>

                    {/* This is for numbered list  */}
                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('GetPenaltiesRemoved')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('HelpYouToSaveThousandsDollars')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('RequestAndSetupInstallmentAgreement')}</span>
                            &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('ActivateHardshipAgreement')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('UpdateYourAdressWithIrs')}</span>
                        </ListGroup.Item>

                        {['Yes', 'NotSure'].includes(formData.UnfiledReturn) && (
                            <ListGroup.Item as="li" style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('FileUnfiledTaxReturns')}</span>
                                &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('BringYourAccountIntoGoodStanding')}</span>
                            </ListGroup.Item>
                        )}

                        {formData.ContactReason?.includes('Lien Placed On Property') && (
                            <ListGroup.Item as="li" style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('RequestRemovalTaxLien')}</span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>

                    <p className='mt-4'>{translate('InstantAccessToIrsTaxAssistant')}</p>

                    <h2 className='mt-4' style={{ color: '#FF0000' }}>{formData.FirstName} {translate('OutcomesYouWillAbleToCreateForYourself')}</h2>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('PreventBankLevies')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('StopIrsInvestigations')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('ReduceIrsAuditAssessments')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('EliminateTaxPenalties')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span style={{ fontWeight: 'bold' }}>{translate('ObtainFavorableTaxSettlements')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('SuccessfullyRepresentTaxpayers')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <h2 className='mt-4'>
                        <span style={{ color: '#0f4761' }}> {translate('299p41')}</span>
                    </h2>
                    <p className='mt-4'>{translate('TryingToRefinanceOrPurchaseProperty')}</p>

                    <h2 className='mt-4'>
                        <span style={{ color: '#0f4761' }}> {formData.FirstName} </span>{translate('WhatYouGet')}
                    </h2>

                    <p className='mt-4'>{translate('GetInstantAccessToTaxAssistant')}</p>
                    <p className='mt-4'>{translate('SayGoodbyeToStressAndConfusion')}</p>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: 240,
                                borderRadius: 0,
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                border: 'none'  // Optionally remove border if desired
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>

                    <h2 className='mt-4' style={{ color: '#0f4761' }}>
                        {formData.FirstName} {translate('WhatCanTaxAssistantWizardsHelpYouDo')}
                    </h2>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('RequestAnInstallmentPaymentPlan')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('SubmitChangeOfAddressToIrsQuickly')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('ApplyForPenaltyAbatement')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('CompleteFinancialDisclosures')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('RequestTaxLienRemoval')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: 240,
                                borderRadius: 0,
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                border: 'none'  // Optionally remove border if desired
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>

                    <h2 className='mt-4' style={{ color: '#0f4761' }}>
                        {formData.FirstName} {translate('HowItWorks')}
                    </h2>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('AccessSecurely')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('PickYourWizard')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('AnswerSimpleQuestions')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('InstantDocumentPrep')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('DownloadAndSign')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('MailToIrs')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('EnjoyPeaceOfMind')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: 240,
                                borderRadius: 0,
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                border: 'none'  // Optionally remove border if desired
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>

                    <h2 className='mt-4' style={{ color: '#0f4761' }}>
                        {formData.FirstName} {translate('MoneyBackGuarantee')}
                    </h2>

                    <p className='mt-4'>{translate('AutomatedTaxCreditsConfidence')}</p>
                    <p className='mt-4'><a href="https://automatedtaxcredits.com/money-back-guarantee/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>{translate('WhyWeProvideMoneyBackGuarantee')}</a></p>
                    <p className='mt-4'>{translate('IrsSupport')}</p>
                    <p className='mt-4'>{translate('OutcomesWithTaxAssistant')}</p>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('PreventBankLevies')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('StopIrsInvestigations')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('ReduceIrsAuditAssessments')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('EliminateTaxPenalties')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('ObtainFavorableTaxSettlements')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            <span>{translate('SuccessfullyRepresentTaxpayers')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: 240,
                                borderRadius: 0,
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                border: 'none'  // Optionally remove border if desired
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Thanks299;
