import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { protectAssetsPageLoadedEvent, protectAssetsPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios'

function ProtectAssets({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const translate = (key) =>
    {
        return t(`ProtectAssets.${key}`);
    };

    useEffect(() =>
    {
        if (activeKey === 7)
        {
            protectAssetsPageLoadedEvent();
        }

    }, [activeKey]);

    const next = async (e) =>
    {

        await updateToXano(formData.XanoId, '7');

        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);
        setFormData({
            ...formData,
            ProtectAssets: e
        });

        protectAssetsPageCompletedEvent();
    };

    const updateToXano = async (id, stage) =>
        {
            setLoading(true);
    
            const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
            const payload = { 
                id,
                Stage: stage,
             };
    
            try
            {
                await axios.patch(url, payload);                      
                setLoading(false);
            }
            catch (e)
            {
                setLoading(false);
                console.log('Error: ', e);
            }
        };

    return (
        <div className="initial-step-container">
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('PersonalOrBusinessAssetsNeedProtectingFromIrs')}</h2>
                </Col>
            </Row>


            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('Yes');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('Yes')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('No');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('No')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() =>
                        {
                            next('NotSure');
                        }}
                        style={{ width: 240, borderRadius: 0 }}
                    >
                        {translate('NotSure')}
                    </Button>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>
    );
}

export default ProtectAssets;
