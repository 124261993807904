import React, { useEffect, useState } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "../asset/css/nunito-font.css";
import "./Landing.css";
import TaxType from './TaxType';
import PaymentPlan from './PaymentPlan';
import IssueReason from './IssueReason';
import Zipcode from './Zipcode';
import UnfiledReturn from './UnfiledReturn';
import IrsOwe from './IrsOwe';
import ProtectAssets from './ProtectAssets';
import ContactReason from './ContactReason';
import ContactInfo from './ContactInfo';
import Thanks299 from './Thanks299';
import Thanks9 from './Thanks9';
import Upsell299 from './Upsell299';
import Checkout9 from './Checkout9';
import Checkout299 from './Checkout299';
import PaymentSuccess from './PaymentSuccess';
import GeneralFooter from '../component/GeneralFooter';
import ThanksFooter from '../component/ThanksFooter';
import CheckoutFooter from '../component/CheckoutFooter';
import PostPurchaseFooter from '../component/PostPurchaseFooter';
import Header from '../component/Header';
import LanguageSwitcher from '../component/LanguageSwitcher';


function LandingPage()
{
    const location = useLocation();

    const [activeKey, setActiveKeyRef] = useState(1);  // I changed the key from 1 to 10 so it always load the 10th step. this way I don't have to fill the form again ang agin.
    const [formData, setFormDataRef] = useState({});
    const [optedIn, setOptedIn] = useState(false);

    const getQueryParams = (search) =>
    {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const showComponent = !queryParams.get('full');
    const directLinkTo299 = queryParams.get('p') === '299';

    const setActiveKey = (e) =>
    {
        setActiveKeyRef(e);

        const scrollToTop = () =>
        {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
    };

    const setFormData = (e) =>
    {
        setFormDataRef(e);
    };

    const navItems = [
        { eventKey: 1, title: "TaxType" },
        // { eventKey: 2, title: "PaymentPlan" },
        { eventKey: 3, title: "IssueReason" },
        // { eventKey: 4, title: "Zipcode" },
        { eventKey: 5, title: "UnfiledReturn" },
        { eventKey: 6, title: "IrsOwe" },
        { eventKey: 7, title: "ProtectAssets" },
        { eventKey: 8, title: "ContactReason" },
        // { eventKey: 10, title: "Thanks299" },
        { eventKey: 9, title: "ContactInfo" }, // Opt-in form page
        { eventKey: 10, title: "Thanks9" },  // Thanks 9 page
        { eventKey: 11, title: "Checkout9" },  // Checkout9 page
        { eventKey: 12, title: "Upsell299" },  // Upsell299 page
        { eventKey: 13, title: "Thanks299" },  // Thanks299 page
        { eventKey: 14, title: "Checkout299" },  // Checkout299 page
        { eventKey: 15, title: "PaymentSuccess" },  // Success page
    ];

    return (
        <Container className="text-center">
            {showComponent && <Header />}

            <div className="form-body position-relative">
                <Tab.Container
                    defaultActiveKey={1}
                    activeKey={activeKey}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <LanguageSwitcher />

                    <Nav variant="tabs">
                        {navItems.map((item, idx) => (
                            <Nav.Item key={idx}>
                                <Nav.Link
                                    eventKey={item.eventKey}
                                    className={activeKey > item.eventKey ? "passed" : ""}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {item.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={1} title="TaxType">
                            <TaxType
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2} title="PaymentPlan">
                            <PaymentPlan
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={3} title="IssueReason">
                            <IssueReason
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={4} title="IssueReason">
                            <Zipcode
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={5} title="UnfiledReturn">
                            <UnfiledReturn
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={6} title="IrsOwe">
                            <IrsOwe
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={7} title="ProtectAssets">
                            <ProtectAssets
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={8} title="ContactReason">
                            <ContactReason
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={9} title="ContactInfo">
                            <ContactInfo
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                                directLinkTo299={directLinkTo299}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={10} title="Thanks9">
                            <Thanks9
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={11} title="Checkout9">
                            <Checkout9
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={12} title="Upsell299">
                            <Upsell299
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={13} title="Thanks299">
                            <Thanks299
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={14} title="Checkout299">
                            <Checkout299
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={15} title="PaymentSuccess">
                            <PaymentSuccess
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            {/* And here are the separate footers */}
            {/* All the steps except 10, 11, 12, 13 will have General Footer */}
            {showComponent && ![10, 11, 12, 13].includes(activeKey) && <GeneralFooter />}
            {/* Step 10 have Thanks Footer as this is Thanks299 page  */}
            {showComponent && activeKey === 10 && <ThanksFooter />}
            {/* Step 11 checkout page and Footer  */}
            {showComponent && activeKey === 11 && <CheckoutFooter />}
            {/* Step 12 post purchase page and Footer  */}
            {showComponent && activeKey === 12 && <PostPurchaseFooter />}
            {/* Step 13 Thanks9 page and Footer  */}
            {showComponent && activeKey === 13 && <ThanksFooter />}
        </Container>
    );
}

export default LandingPage;
